import React from 'react';
import Layout from '../components/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <Layout>
    <section
      className='section is-large has-background-dark'
      style={{ minHeight: '100vh' }}
    >
      <div className='container'>
        <div className='columns is-vcentered'>
          <div className='column has-text-centered'>
            <div className='section-heading'>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                size='6x'
                color='#ec1a36'
              />
              <h1 className='has-text-white is-size-1'>404 Page Not Found</h1>
              <p className='subtitle mt-1 has-text-white'>
                Sorry, we can't find that page you're looking for.
              </p>
              <Link to='/'>
                <button type='button' className='button is-medium is-outlined'>
                  Home
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
